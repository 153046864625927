import React, { useEffect, useState } from "react";
import p1 from "../assets/profile/Rectangle 171.png";
import p2 from "../assets/profile/Rectangle 171-1.png";
import p3 from "../assets/profile/Rectangle 171-2.png";
import household from "../assets/images/services/Rectangle 172-4.png";
import household2 from "../assets/images/services/Rectangle 176-7.png";

import office_relocation from "../assets/images/services/Rectangle 172-2.png";
import office_relocation2 from "../assets/images/services/Group 861.png";

import mobility_service from "../assets/images/services/Rectangle 172-3.png";
import mobility_service2 from "../assets/images/services/Rectangle 176.png";

import road_transportation from "../assets/images/services/Rectangle 173.webp";
import road_transportation2 from "../assets/images/services/Rectangle 176-4.webp";

import packing_and_crafting from "../assets/images/services/Rectangle 172.png";
import packing_and_crafting2 from "../assets/images/services/Rectangle 176-1.png";

import groupage_services from "../assets/images/services/Rectangle 172-1.png";
import groupage_services2 from "../assets/images/services/Rectangle 176-6.png";

import storage_services from "../assets/images/services/Rectangle 176-3.png";
import storage_services2 from "../assets/images/services/Rectangle 176-3.png";

import vehicle_services from "../assets/images/services/Rectangle 176-5.png";
import vehicle_services2 from "../assets/images/services/Rectangle 176-5.png";

import PilgrimBaggage from "../assets/images/services/Pilgrim Baggage Handling.webp";
import PilgrimBaggage1 from "../assets/images/services/Group 1020 (1).webp";

import icon from "../assets/images/icons/Group.svg";
import icon_arrow from "../assets/images/icons/Arrow 1.png";
import { getDataFromContentful } from "../libs/contentful/data";

import { Autoplay } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";

export const serviceslist = [
  {
    id: 1,
    image: household,
    image2: household2,
    heading: "Household Goods Relocation",
    description:
      "Undertaking household goods relocation to make easy and convenient moving.",
  },

  {
    id: 2,
    image: office_relocation,
    image2: office_relocation2,
    heading: "Office Relocation & Data Centre Migration ",
    description:
      "Planning and relocating offices with our skilled movers and packers.",
  },
  {
    id: 3,
    image: mobility_service,
    image2: mobility_service2,
    heading: "Mobility Services",
    description:
      "Assisting companies to handle the complex tax, planning, operational, and strategic aspects in all levels.",
  },
  {
    id: 4,
    image: road_transportation,
    image2: road_transportation2,
    heading: "Road Transportation",
    description:
      "We handle both standard and heavylift transport We provide is a variety of solutions to meet the demand of customer.",
  },
  {
    id: 5,
    image: packing_and_crafting,
    image2: packing_and_crafting2,
    heading: "Packing & Crating",
    description:
      "We have a team of professional carpenters and expert packers.",
  },
  {
    id: 6,
    image: groupage_services,
    image2: groupage_services2,
    heading: "Groupage Services",
    description:
      "We provide domestic and international transportation even for less volume load along with other shipments.",
  },
  {
    id: 7,
    image: storage_services,
    image2: storage_services2,
    heading: "Storage Services",
    description:
      "We provide domestic and international transportation even for less volume load along with other shipments.",
  },
  {
    id: 8,
    image: vehicle_services,
    image2: vehicle_services2,
    heading: "Vehicle Relocations",
    description:
      "We provide domestic and international transportation even for less volume load along with other shipments.",
  },
  {
    id: 9,
    image: PilgrimBaggage1,
    image2: PilgrimBaggage,
    heading: "Pilgrim Baggage Handling",
    description:
      "Pilgrim baggage handling services ensure a smooth and convenient experience for Umrah and Hajj pilgrims visiting Makkah and Madinah. We collect baggage directly from pilgrims, manage customs at Jeddah and provide safe, door-to-door delivery worldwide, especially to Asian countries, allowing pilgrims to focus on their religious obligations.",
  },
];

function Services({ type, name, see_all_btn, page }) {
  const [customerFeedback, setCustomerFeedback] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (type === "customer") {
      const d = await getDataFromContentful({ type: "reviews" });

      setCustomerFeedback(d);
    }
  }, [type]);

  return (
    <>
      {/* begin */}

      <section
        id="page-title"
        className="bg-transparent sm:px-[30px] px-[16px] lg:px-[100px]"
      >
        <div className="flex flex-row w-full items-start justify-between h-full gap-1">
          <span className="lg:text-[64px] text-[26px] flex flex-row gap-[20px] items-center mr-[70px]">
            <img src={icon} className="object-contain h-[45px]" alt="" />
            <div className="flex items-start gap-[18px] flex-wrap">
              {name.split(" ").map((text, idx) => (
                <p
                  key={idx}
                  className={`font-vetica ${
                    idx === 0 ? "text-[#03719a]" : "text-[#00aeef] -ml-[10px]"
                  } xl:text-5xl lg:text-5xl leading-3 text-[36px] lg:shrink-0 ld:w-max `}
                >
                  {text}
                </p>
              ))}
            </div>
          </span>

          {see_all_btn && (
            <a
              href={"/services"}
              className="btn btnprimary btn-see-all d-flex justify-content-center align-items-center  w-[88px] h-[36px] lg:w-[170px] lg:h-[52px] lg:rounded-[15px] rounded-[5px] hover:bg-[#03719A] duration-300 relative"
            >
              See all{" "}
              <img
                src={icon_arrow}
                style={{ height: "10px", marginLeft: "5px" }}
                alt=""
              />
            </a>
          )}
        </div>
      </section>
      <section id="content">
        <div className="content-wrap p-0 mb-20">
          <div className="container clearfix">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4  w-full">
              {page === "home" &&
                serviceslist.map((service, index) => {
                  if (index === 7 || index === 6 || index === 4) {
                    return <React.Fragment key={index}></React.Fragment>;
                  } else {
                    return <ServiceCard key={index} service={service} />;
                  }
                })}

              {window.location.pathname === "/services" &&
                serviceslist.map((service, index) => {
                  return (
                    <ServiceCardInside
                      key={index}
                      service={service}
                      index={index + 1}
                    />
                  );
                })}
            </div>
            {type === "customer" && customerFeedback && (
              <div className="w-full h-full flex">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  modules={[Autoplay]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },

                    680: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                  }}
                  loop
                  className="mySwiper w-full "
                >
                  {customerFeedback.data.reviewCollection.items.map(
                    (item, index) => (
                      <SwiperSlide key={index} className="w-full h-auto">
                        <CustomerFeedback item={item} />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* end */}
    </>
  );
}

export default Services;

const ServiceCard = ({ service }) => {
  return (
    <div className="w-full flex">
      <a
        href={`/service/${service.id}`}
        className="xteam service-card bg-primary-light"
      >
        <div className="team-image max-h-[265px] overflow-hidden">
          <img src={service.image} className="service-card " alt="img" />
        </div>
        <div className="team-desc h-auto py-3">
          <div className="xteam-title p-3 d-flex flex-column ">
            <span
              className="service-heading d-flex align-items-end text-[28px] leading-[37px] text-[#005170]"
              // style={{ minHeight: '40px' }}
            >
              {service.heading}
            </span>
            <span className="text-[22px] text-[#03719A] leading-[26px] mt-2 ">
              {service.description}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

const ServiceCardInside = ({ service, index }) => {
  return (
    <div className="w-full">
      <div className="xteam service-card  d-flex align-items-center justify-content-center w-full">
        <div
          className="team-image  d-flex justify-content-center align-items-center w-full"
          style={{
            position: "relative",
            // maxWidth: '330px',
          }}
        >
          <img
            src={service.image2}
            className="service-card "
            style={{
              width: "100%",
            }}
            alt="img"
          />
          <div
            className="d-flex justify-content-between align-items-end"
            style={{
              height: "80px",
              width: "100%",
              paddingLeft: "20px",
              paddingRight: "20px",

              position: "absolute",
              color: "white",
              bottom: "20px",
            }}
          >
            <span className="text-[26px] leading-[30px] p-3">
              {service.heading}
            </span>
            <a
              href={`/service/${index}`}
              className="h-[75px] w-[75px] flex items-center justify-center rounded-[20px] bg-[#002A39] cursor-pointer shrink-0"
            >
              <img
                src={icon_arrow}
                style={{ height: "15px" }}
                className="object-contain"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerFeedback = ({ item }) => {
  return (
    <div className="col-sm-6 col-lg-4 col-12 d-flex justify-content-center w-full h-">
      <div
        className="team service-card bg-primary-light customer-feedback duration-300 ease-in group w-full h-full"
        style={{}}
      >
        <div className="xteam-desc h-full w-full">
          <div className="xteam-title p-3 h-full w-full">
            <div style={{ display: "flex" }}>
              {/* <img src={item.profile_pic} alt="" /> */}
              <div
                style={{
                  padding: "0px 0px 0px 0px",
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="customer-feedback-heading capitalize font-vetica duration-300 ease-in">
                  {item.name}
                </div>
                <div className="customer-feedback-desc duration-300 ease-in">
                  {item.location}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-base leading-[13px] group-hover:text-white duration-300 ease-in">
                {item.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
